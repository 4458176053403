import React, { useState, useEffect, useCallback } from "react"
import { Parallax } from "react-parallax"

export default function ParallaxSection({ src, altTag, height }) {
  const [width, setWidth] = useState(0)

  const updateWidth = useCallback(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }, [])

  useEffect(() => {
    updateWidth()
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWidth)
      return () => window.removeEventListener('resize', updateWidth)
    }
  }, [updateWidth])

  if (!src) return null

  return (
    <Parallax
      bgImage={src}
      bgImageAlt={altTag}
      strength={0}
      disabled={width <= 350}
    >
      <div
        style={{ height: width > 1500 ? 500 : width <= 400 ? 100 : height }}
      />
    </Parallax>
  )
}

ParallaxSection.defaultProps = {
  altTag: "",
  height: 350,
  src: "",
}